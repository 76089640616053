import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject, takeUntil } from 'rxjs';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class EyAuthService {
  constructor(private router: Router, private http: HttpClient,private cookieService: CookieService) { }

  loginFormGroup: FormGroup;
  private destroy$ = new Subject<void>();

  setToken(token: string): void {
    sessionStorage.setItem('token', token);
  }

  getToken(): string | null {
    return sessionStorage.getItem('token');
  }

  IsLoggedIn() {
    return this.getToken() != null;
  }

  logout() {
    try{
      this.loginFormGroup = new FormGroup({
        IsLogOut : new FormControl(true),
        IsGenAI : new FormControl(false)
      })
      this.insertAuthToken(this.loginFormGroup.value).pipe(takeUntil(this.destroy$))
      .subscribe((result) => {

      })
    }
    catch(ex)
    {
      console.log("Error Occured in logout :" + ex);
    }
    sessionStorage.removeItem('token');
    sessionStorage.clear();
    this.cookieService.deleteAll();
    this.router.navigate(['ey-dashboard']);
  }


  private headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: '*/*'
  });

  AuthenticateInstituteUserForEY(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.APIUrl}EY/AuthenticateInstituteUserForEY`, body, options);
  }

  insertAuthToken(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.APIUrl}Institute/InsertAuthToken`, body, options);
  }
}
