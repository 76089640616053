import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject, catchError, takeUntil, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DataStoreService } from './data-store.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private router: Router, private http: HttpClient,
    private cookieService: CookieService,private dataStoreService: DataStoreService) { }

  formPrgGroup: FormGroup;
  private destroy$ = new Subject<void>();
  private triggerSettingsInit = new Subject<void>();
  private triggerSideBarInit = new Subject<void>();

  loginFormGroup: FormGroup;

  browser: string;
  browserVersion: string;
  os: string;
  ipAddress:string;

  setToken(token: string): void {
    sessionStorage.setItem('token', token);
  }

  getToken(): string | null {
    return sessionStorage.getItem('token');
  }

  IsLoggedIn() {
    return this.getToken() != null;
  }

  logout() {
    try{
      this.loginFormGroup = new FormGroup({
        IsLogOut : new FormControl(true),
        IsGenAI : new FormControl(false)
      })
      this.insertAuthToken(this.loginFormGroup.value).pipe(takeUntil(this.destroy$))
      .subscribe((result) => {

      })
    }
    catch(ex)
    {
      console.log("Error Occured in logout :" + ex);
    }
    sessionStorage.removeItem('token');
    sessionStorage.clear();
    this.cookieService.deleteAll();
    this.router.navigate(['login']);
  }

  detectBrowser() {
    const userAgent = window.navigator.userAgent;
    
    if (userAgent.indexOf("Chrome") != -1) {
      this.browser = "Google Chrome";
    } else if (userAgent.indexOf("Safari") != -1) {
      this.browser = "Safari";
    } else if (userAgent.indexOf("Firefox") != -1) {
      this.browser = "Firefox";
    } else if (userAgent.indexOf("Edge") != -1) {
      this.browser = "Microsoft Edge";
    } else if (userAgent.indexOf("Opera") != -1 || userAgent.indexOf("OPR") != -1) {
      this.browser = "Opera";
    } else if (userAgent.indexOf("Trident") != -1) {
      this.browser = "Internet Explorer";
    } else {
      this.browser = "Unknown";
    }

    // Extracting version number
    const versionStartIndex = userAgent.indexOf(this.browser) + this.browser.length + 1;
    this.browserVersion = userAgent.substring(versionStartIndex).split(' ')[0];
    return this.browser;
  }

  detectOS() {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;

    if (platform.indexOf('Win') !== -1) {
      this.os = 'Windows';
    } else if (platform.indexOf('Mac') !== -1) {
      this.os = 'MacOS';
    } else if (platform.indexOf('Linux') !== -1) {
      this.os = 'Linux';
    } else if (/Android/.test(userAgent)) {
      this.os = 'Android';
    } else if (/iOS|iPadOS|iPhone/.test(userAgent)) {
      this.os = 'iOS';
    } else {
      this.os = 'Unknown';
    }
    return this.os;
  }

  detectIP()
  {
    this.getIPAddress().then(ipAddress => {
      this.ipAddress = ipAddress;
    });
  }

  async getIPAddress() {
    let ipAddress = '';
    try {
      const response = await fetch('https://api.ipify.org/');
      if (response.ok) {
        const data = await response.text();
        ipAddress = data;
      } else {
        console.error('Failed to fetch IP address:', response.status);
      }
    } catch (error) {
      console.error('Error fetching IP address:', error);
    }
    return ipAddress;
  }

  private headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: '*/*'
  });

  login(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.APIUrl}Institute/AuthenticateInstituteUser`, body, options);
  }

  createNewInstituteRecord(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.APIUrl}Institute/CreateNewInstituteRecord`, body, options);
  }

  updateEmailActivation(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.APIUrl}Institute/UpdateEmailActivation`, body, options);
  }

  GetInstituteDetails(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.APIUrl}Institute/GetInstituteDetails`, body, options);
  }

  checkEmailVerification(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.APIUrl}Institute/CheckEmailVerification`, body, options);
  }

  saveInstituteDetails(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.APIUrl}Institute/SaveInstituteDetails`, body, options);
  }

  resendEmail(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.APIUrl}Institute/ResendEmail`, body, options);
  }

  insertAuthToken(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.APIUrl}Institute/InsertAuthToken`, body, options);
  }

  addInstitute(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.APIUrl}Institute/AddInstitute`, body, options);
  }

  GetProgrammingToken(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: '*/*',
        'Skip-Interceptor': 'true',
      })
    };
    return this.http.post(`${environment.ProgrammingUrl}Home/InstituteLogin`, body, options);
  }

  setAllSessions(result, Username) {
    try {
      this.setToken(result.Data.Token);


      sessionStorage.setItem('param1', result.Data.InstituteID);
      sessionStorage.setItem('param2', result.Data.UserType);
      sessionStorage.setItem('param3',  result.Data.encInstituteEmailID); //InstituteUserEmailID
      sessionStorage.setItem('param4', result.Data.InstituteUserID);
     // sessionStorage.setItem('param4', result.Data.LastLogin);

      //sessionStorage.setItem('BranchWiseLogoUpload',result.Data.BranchWiseLogoUpload);
      // sessionStorage.setItem('InstituteID', result.Data.InstituteID);
      // sessionStorage.setItem('UserType', result.Data.UserType);
      // sessionStorage.setItem('InstituteUserEmailID', Username);
      // sessionStorage.setItem('LastLogin', result.Data.LastLogin);
      //sessionStorage.setItem('GooglePlusLogin', '0');
      // sessionStorage.setItem('InstituteUserPassword',this.formGroup.value.Password);
      // sessionStorage.setItem('InstituteUserID', result.Data.InstituteUserID);
      // sessionStorage.setItem('UserName', result.Data.UserName);
      // sessionStorage.setItem('InstituteName', result.Data.InstituteName);
      // sessionStorage.setItem('PackageID', result.Data.PackageID);
      // sessionStorage.setItem('InstituteMobile', result.Data.InstituteMobile);
      //sessionStorage.setItem('QuestionCount',result.Data.QuestionCount);
      //sessionStorage.setItem('No_of_Exams_Created',result.Data.No_of_Exams_Created);
      //sessionStorage.setItem('UsersAdded',result.Data.UsersAdded);
      // sessionStorage.setItem('created_at', result.Data.created_at);
      // sessionStorage.setItem('Plan', result.Data.Plan);
      //sessionStorage.setItem('ExamSessionsConducted',result.Data.ExamSessionsConducted);
      // sessionStorage.setItem('IsDataEncrypted', result.Data.IsDataEncrypted);
      // sessionStorage.setItem('IsInstituteHasBranches', result.Data.IsInstituteHasBranches);
      // sessionStorage.setItem('Isparagraphbased', result.Data.Isparagraphbased);
      // sessionStorage.setItem('IsUnlimitedExam', result.Data.IsUnlimitedExam);
      // sessionStorage.setItem('IsAutoProctored', result.Data.IsAutoProctored);
      // sessionStorage.setItem('IsInstituteHasImageBasedOptions', result.Data.IsInstituteHasImageBasedOptions);
      // sessionStorage.setItem('IsInstituteHasExamCode', result.Data.IsInstituteHasExamCode);
      //sessionStorage.setItem('IsPDFDownload',result.Data.IsPDFDownload);
      // sessionStorage.setItem('IsMathJaxReports', result.Data.IsMathJaxReports);
      // sessionStorage.setItem('ExamMonthAvailable', result.Data.ExamMonthAvailable);
      //sessionStorage.setItem('IsDifficultyLevelwiseResult',result.Data.IsDifficultyLevelwiseResult);
      // sessionStorage.setItem('IsMultiselect', result.Data.IsMultiselect);
      //sessionStorage.setItem('IsSurveyLinkEnable',result.Data.IsSurveyLinkEnable);
      //sessionStorage.setItem('IsSendCertificate',result.Data.IsSendCertificate);
      //sessionStorage.setItem('Isknowledgebasestreaming',result.Data.Isknowledgebasestreaming);
      //sessionStorage.setItem('IsAPIEnabled',result.Data.IsAPIEnabled);
      //sessionStorage.setItem('IsHtmlReports',result.Data.IsHtmlReports);
      //sessionStorage.setItem('IsEmailActivation',result.Data.IsEmailActivation);
      // sessionStorage.setItem('IsMobileOptional', result.Data.IsMobileOptional);
      // sessionStorage.setItem('IsDistanceLearning', result.Data.IsDistanceLearning);
      //sessionStorage.setItem('LogoDimension',result.Data.LogoDimension);
      //sessionStorage.setItem('IsNewCertificate',result.Data.IsNewCertificate);
      //sessionStorage.setItem('IsInstituteHasGraceMarks',result.Data.IsInstituteHasGraceMarks);
      //sessionStorage.setItem('IsSchoolStructure',result.Data.IsSchoolStructure);
      //sessionStorage.setItem('IsInstituteHasReAttemptCount', result.Data.IsInstituteHasReAttemptCount);
      //sessionStorage.setItem('KnowledgeSharing',result.Data.KnowledgeSharing);
      //sessionStorage.setItem('IsBulkApprove',result.IsBulkApprove);
      //sessionStorage.setItem('IsWhiteLabel',result.IsWhiteLabel);
      // sessionStorage.setItem('Iscandidatenameshowtoexaminer',result.Data.Iscandidatenameshowtoexaminer);
      //sessionStorage.setItem('BranchID', result.BranchID);
      //sessionStorage.setItem('IsShowOldReviewReport',result.Data.IsShowOldReviewReport);
      //sessionStorage.setItem('IsReGenerateResultEnabled',result.IsReGenerateResultEnabled);
      //sessionStorage.setItem('IsEnabledArchiveDB',result.IsEnabledArchiveDB);
      // sessionStorage.setItem('AllowSaveStreaming', result.Data.AllowSaveStreaming);
      // sessionStorage.setItem('SubscriptionEndDate', result.Data.SubscriptionEndDate);
      //sessionStorage.setItem('IsMoodleIntegrationEnabled',result.IsMoodleIntegrationEnabled);
      //sessionStorage.setItem('IsProgrammingQues', result.Data.IsProgrammingQues);
      //sessionStorage.setItem('IsInstituteHasRandomDates',result.IsInstituteHasRandomDates);
      //  sessionStorage.setItem('CloudType', result.Data.CloudType);
      //sessionStorage.setItem('HeaderBGColor',result.HeaderBGColor);
      //sessionStorage.setItem('HeaderLabelColor',result.HeaderLabelColor);
      //sessionStorage.setItem('LoginwithDocumentProof',result.LoginwithDocumentProof);
      // sessionStorage.setItem('Isway2communication', result.Data.Isway2communication);
      // sessionStorage.setItem('TodaysExamStats', result.Data.TodaysExamStats);
      // sessionStorage.setItem('IsAllowProfPhotoCompare', result.Data.IsAllowProfPhotoCompare);
      // sessionStorage.setItem('IsAdvanceAISetting', result.Data.IsAdvanceAISetting);
      // sessionStorage.setItem('IsOldAI', result.Data.IsOldAI);
      //sessionStorage.setItem('No_of_Subjects_Created',result.No_of_Subjects_Created);
      //sessionStorage.setItem('No_of_Topics_Created',result.No_of_Topics_Created);
      //sessionStorage.setItem('Total_Exam_Schedules',result.Total_Exam_Schedules);
      //sessionStorage.setItem('Total_Exams_Assigned',result.Total_Exams_Assigned);
      //sessionStorage.setItem('Plan_Expiry_Date_At',result.Plan_Expiry_Date_At);
      //sessionStorage.setItem('IsMisReport',result.IsMisReport);
      //sessionStorage.setItem('Zoho_CustomerID',result.Zoho_CustomerID);
      // sessionStorage.setItem('AllowAudioRecognition', result.AllowAudioRecognition);
      // sessionStorage.setItem('IsAutoGeneratedQue', result.Data.IsAutoGeneratedQue);
      //sessionStorage.setItem('RoleName',result.RoleName);
      //sessionStorage.setItem('IsFeedBack', result.Data.IsFeedBack);
      //sessionStorage.setItem('isPartialMarksForMTP',result.isPartialMarksForMTP);
      // sessionStorage.setItem('IsSkillSet', result.IsSkillSet);
      //sessionStorage.setItem('Signup_Process_Completed',result.Signup_Process_Completed);
      //sessionStorage.setItem('Payment_Done',result.Payment_Done);
      // sessionStorage.setItem('IsLatexEditor', result.Data.IsLatexEditor);
      // sessionStorage.setItem('AIQuestionCredit', result.Data.AIQuestionCredit);
      // sessionStorage.setItem('IsExamwiseLogo', result.Data.IsExamwiseLogo);
      // sessionStorage.setItem('ShowRankingDashboard', result.Data.ShowRankingDashboard);
      // sessionStorage.setItem('IsAllowPsychometricQuestion', result.Data.IsAllowPsychometricQuestion);

      this.GenerateTokenForProgramming();
      sessionStorage.setItem('IsBlobAccessType', result.Data.IsBlobAccessType);
      
      if (result.Data.UserType == 7 || result.Data.UserType == 5 || result.Data.UserType == 9) {
        sessionStorage.setItem('SubjectID', result.Data.CategoryID);
      }
      else {
        sessionStorage.setItem('SubjectID', '0');
      }
      sessionStorage.setItem('AdminLogoUrl', result.Data.LogoUrl);
      sessionStorage.setItem('IsMultiExaminer', result.Data.IsMultiExaminer);
    }
    catch (ex) {
      console.log(ex);
    }
  }

  GenerateTokenForProgramming() {
    try {
      this.formPrgGroup = new FormGroup({
        InstituteId: new FormControl(sessionStorage.getItem('param1')),
        InstitutePass: new FormControl('eklavvya'),
      });

      this.GetProgrammingToken(this.formPrgGroup.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (result) => {
            sessionStorage.setItem('PrgToken', result.token);
          }, (error: Error) => {
            console.log(error.message);
          }
        )

    } catch (ex) {
      console.log("Error in GenerateTokenForProgramming", ex);
    }
  }


  triggerSettingsInitialization() {
    this.triggerSettingsInit.next();
  }

  getSettingsInitObservable() {
    return this.triggerSettingsInit.asObservable();
  }

  triggerSideBarInitialization() {
    this.triggerSideBarInit.next();
  }

  getSideBarInitObservable() {
    return this.triggerSideBarInit.asObservable();
  }
}
