
  
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
        integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"
        integrity="sha512-iBBXm8fW90+nuLcSKlbmrPcLa0OT92xO1BIsZ+ywDWZCvqsWgccV3gFoRBv0z+8dLJgyAHIhR35VZc2oM/gI1w=="
        crossorigin="anonymous" referrerpolicy="no-referrer" />
    <style>
        .cmp-logo__link svg {
            fill: white;
            height: 55px;
            -webkit-transition: all .3s ease-out;
            transition: all .3s ease-out;
            width: 113px;
        }
        .form-control{
            height: calc(1.5em + 0.75rem + 2px);
        }
        .mainDiv {
            background-image:url(../../../assets/images/login-bg.jpg);
            height: 100%;
        }

        .login-box {
            background: #ffffff;
    border-bottom: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: .3s ease;
        }

        .login-box h2 {
            color: #002855;
            margin-bottom: 20px;
        }



        .login-box input[type="password"] {
            margin-bottom: 30px;
        }

        .login-box button {
            background-color: #1b4697;
    color: #fff;
    padding: 8px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    text-transform: uppercase;
        }
        .align-items-center {
    height: 100%;
}

        .login-box button:hover {
            opacity: 0.8;
        }

        .ey-logo {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 30px;
        }

        .ey-logo img {
            width: 150px;
        }

        .slogan {
            text-align: center;
            color: #fff;
            margin-bottom: 20px;
        }

        .edit-icon {
            position: absolute;
    top: 12px;
    right: -15px;
    height: 40px;
    width: 40px;
    background-color: #ffe600;
    border-radius: 50%;
    padding: 10px 10px;
    box-shadow: 1px 3px 4px 1px #0000003b;
            cursor: pointer;
        }

        .edit-icon i {
            color: #000;
            font-size: 17px;
        }

        .user-icon {
            position: relative;
            top: 5px;
            left: 10px;
            color: #000;
        }

        .lock-icon {
            position: relative;
            top: 5px;
            left: 10px;
            color: #000;
        }
        .formBody{
            padding: 8%;
        }
        .loginHead{
            padding: 3% 5% 0% 5%;
    border-left: 5px solid #ffe600;
        }
        .logo-text{
            text-transform: uppercase;color: #2b96e7;align-items: center;display: flex;justify-content: center;justify-items: center;
        }
        .leftLogoText{
            text-transform: uppercase;
            color: white;
            padding-bottom: 10px;
            border-bottom: 5px solid #ffe600;
            font-size: 1.5rem;
        }
        .login-box::after {
    content: '';
    height: 14px;
    width: 91%;
    position: absolute;
    background: grey;
    /* margin-top: 45px; */
    background: #e3e3e3;
    height: 14px;
    border-radius: 0 0 5px 5px;
    left: 14px;
}
    </style>
    <div class="mainDiv">
    <div class="align-items-center container d-flex justify-content-center">
        <div class="slogan col-sm-8">
            <img src="../../../assets/images/ey-logo-black-and-white.png" class="mb-3" alt="Logo" style="max-width: 35%;">
            <div>
                <span class="leftLogoText">
                    Crafting your personal journey
                </span>
            </div>
        </div>
        <div class="login-box col-md-4 p-0 pt-3">
            <div class="loginHead">
            <h5 class="text-left mb-0" style="text-transform: uppercase;">Talent Dashboard Login</h5>
            </div>

            <form class="formBody">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fas fa-user"></i></span>
                    </div>
                    <input [formControl]="userName" type="text" class="form-control" placeholder="Username" aria-label="Username">
                </div>
                <span *ngIf="isUsername" class="asterisk">*Please enter username</span>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fas fa-lock"></i></span>
                    </div>
                    <input [formControl]="password" type="password" class="form-control m-0" placeholder="password" aria-label="password">
                </div>
                <span *ngIf="isPassword" class="asterisk">*Please enter password</span>
                <span *ngIf="ErrorMessage" class="asterisk text-center">{{ErrorMessage}}</span>
                <button type="submit" class="btn btn-block" (click)="Userlogin()">Submit</button>
            </form>
            <div class="edit-icon">
                <i class="fas fa-pen"></i>
            </div>
        </div>
        <div class="login-boxshadow"></div>

    </div>
    </div>
    <div>
        <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#1f85d6" type="square-jelly-box" [fullScreen]="true">
            <p style="color: white"> {{spinnerText}} </p>
        </ngx-spinner>
    </div>

    <script src="https://kit.fontawesome.com/your-font-awesome-kit-id.js" crossorigin="anonymous"></script>

    <script>
        // Get the form element
        const form = document.querySelector('form');

        // Add an event listener to the form submission
        form.addEventListener('submit', (e) => {
            // Prevent the default form submission behavior
            e.preventDefault();

            // Get the input values
            const username = document.querySelector('input[type="text"]').value;
            const password = document.querySelector('input[type="password"]').value;

            // Validate the input values
            if (username === '' || password === '') {
                alert('Please fill in both fields');
            } else {
                // Submit the form data to the server
                fetch('/login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        username,
                        password
                    })
                })
                    .then((response) => response.json())
                    .then((data) => console.log(data))
                    .catch((error) => console.error(error));
            }
        });
    </script>