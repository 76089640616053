import { Component, ElementRef, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ReCaptcha2Component } from 'ngx-captcha';
import { NgxSpinnerService } from 'ngx-spinner';

/* Declarations from Environment */
import { environment } from 'src/environments/environment';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { Country, CountryList } from 'src/app/interface/Institute Registration/institute-registration';

import { LogService } from "src/app/services/common/log.service";
import { InstituteRegistrationService } from '../../services/institute-registration.service';
import { AuthService } from 'src/app/services/common/auth.service';
import Swal from 'sweetalert2';
import { DataStoreService } from 'src/app/services/common/data-store.service';

@Component({
  selector: 'app-institute-registration',
  templateUrl: './institute-registration.component.html',
  styleUrls: ['./institute-registration.component.scss']
})
export class InstituteRegistrationComponent {
  ErrorMessage: string;
  formGroup: FormGroup;
  createNewInstituteFormGroup: FormGroup;
  isUsername = 0;
  isPassword = 0;
  isPasswordVisible: boolean = false;
  reCaptchaVerfication = null;

  CaptchaSiteKey = environment.recaptcha.siteKey;
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;
  mobileMaxlength: number = 10;

  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'en';
  public type: 'image' | 'audio';

  showForm: boolean = false;
  signUp: boolean = false;
  countries: Country[] = CountryList;
  checkboxError: boolean = false;
  loginFormGroup: FormGroup;
  formSubmitted: boolean = false;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  selectedCountry: string = 'in';
  showError: boolean = false;
  existError: string = '';
  strongPasswordError: boolean = false;
  incoorectMail: boolean = false;
  buttonClicked: boolean = false;
  existErrorGoogle: string = '';
  private destroy$ = new Subject<void>();
  trackedUrls: string[] = [];
  constructor(private route: ActivatedRoute,
    private router: Router,
    private logService: LogService,
    private instituteRegistrationService: InstituteRegistrationService,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private dataStoreService: DataStoreService) { 
    
      
      
    }

  StrongPasswordRegx: RegExp =
    /^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/;

  ngOnInit() {
    this.signUp = true;

   // @ts-ignore
   google.accounts.id.initialize({
    client_id: "854378013722-4aibrpi7r94q6aokfpbtr9kckgspt6sv.apps.googleusercontent.com",
    callback: this.handleCredentialResponse.bind(this),
    use_fedcm_for_prompt: true,
    auto_select: false,
    cancel_on_tap_outside: true,
  });

  // @ts-ignore
  google.accounts.id.renderButton(
    document.getElementById("google-button"),
    {
      theme: "dark",
      size: "large",
      innerHeight: "100px",
      innerWidth: "250px",
      width: "280px",
      text: "Google",
      // text: "Sign up with Google", // Custom text on the button
      shape: "rectangular", // Button shape: 'rectangular' or 'pill'
      isSignedIn: true,    // Display the button only if the user is not signed in
      prompt: "select_account", // Prompt user to select an account
    }
  );

  // @ts-ignore
  google.accounts.id.prompt((notification: PromptMomentNotification) => { });


    const shouldReload = sessionStorage.getItem('shouldReload');

    if (shouldReload === 'false') {
      sessionStorage.setItem('shouldReload', 'true');
      window.location.reload();
    }
    this.formGroup = new FormGroup({
      userName: new FormControl('', Validators.required),
      emailID: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
      countryCode: new FormControl(''),
      mobileNumber: new FormControl('', [Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]),
      password: new FormControl<string>('', {
        validators: [Validators.required, Validators.pattern(this.StrongPasswordRegx)],
      })
    });
  }




  createNewInstituteRecord(isGoogleSignIn?: boolean, username?, password?, emailID?) {
    try {
      this.spinner.show();
      this.strongPasswordError = false;
      this.incoorectMail = false;

      this.checkboxError = false;
      this.formSubmitted = true;
      this.showError = false;
      this.existError = '';
      this.existErrorGoogle = '';
      if (!isGoogleSignIn) {
        if (isGoogleSignIn === undefined || isGoogleSignIn === null) {
          isGoogleSignIn = false;
        }
        const checkbox = document.getElementById("basic_checkbox_1") as HTMLInputElement;

        if (checkbox.checked) {
        } else {
          this.checkboxError = true;
          this.buttonClicked = false;
          this.spinner.hide();
          return;
        }

        let userName = this.formGroup.get('userName').value;
        if (userName === undefined && userName === null && userName === "" && userName === "null") {
          this.buttonClicked = false;
          this.spinner.hide();
          return;
        }
        if (this.formGroup.get('emailID')?.value === undefined && this.formGroup.get('emailID')?.value === null && this.formGroup.get('emailID')?.value === "" && this.formGroup.get('emailID')?.value === "null") {
          this.buttonClicked = false;
          this.spinner.hide();
          return;
        }
        if (this.formGroup.get('password')?.value === undefined && this.formGroup.get('password')?.value === null && this.formGroup.get('password')?.value === "" && this.formGroup.get('password')?.value === "null") {
          this.buttonClicked = false;
          this.spinner.hide();
          return;
        }
        if (this.reCaptchaVerfication == null) {
          alert("Please tick I am not a robot!");
          this.buttonClicked = false;
          this.spinner.hide();
          return;
        }

        const regexPattern = /[^0-9]+/;

        if (regexPattern.test(this.formGroup.get('mobileNumber')?.value)) {
          alert("The contact number cannot contain any characters. Please enter only numeric digits.");
          this.buttonClicked = false;
          this.spinner.hide();
          return;
        }

        if (!this.formGroup.get('emailID')?.value.includes('@')) {
          this.incoorectMail = true;
          this.buttonClicked = false;
          this.spinner.hide();
          return;
        }
       //code to not allow any type of hyperlink in username field
        const forbiddenPatterns=[
          /<script.*?>.*?<\/script>/ig,//script tags
          /<\/?\w+((\s+\w(\s*=\s*(?:".*?"|'.*?'|[^'">\s]+))?)+\s*|\s*)\/?>/ig,//Html tags
          /javascript:/ig,//javascript in urls
          /data:/ig,//datat URIs
          /%[0-9A-Fa-f]{2}/ig,//URL encoded characters
          /<a\s+[^>]*href=["']?([^"'>\s]+)["']?[^>]*>/ig ,//malicious acnchor tags
          /https?:\/\/[^\s]+/ig ,//hyperlink
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/gi//emails
        ];

        for(const pattern of forbiddenPatterns)
        {
          if (pattern.test(userName)) {
            // Remove hyperlinks from the input
           // this.el.nativeElement.value = input.replace(hyperlinkRegex, '');

           Swal.fire({
            icon:"info",
            text:"Please enter a valid username."
           })
            this.buttonClicked = false;
            this.spinner.hide();
            return;
          }
  
  
        }
                this.buttonClicked = true;
        const country = CountryList.find(c => c.code === this.selectedCountry.toLowerCase());
        let CountryCode = country.phoneCode;
        this.createNewInstituteFormGroup = new FormGroup({
          EmailID: new FormControl(this.formGroup.get('emailID')?.value),
          AdminFirstName: new FormControl(this.formGroup.get('userName')?.value),
          AdminLastName: new FormControl(''),
          Password: new FormControl(this.formGroup.get('password')?.value),
          CountryCode: new FormControl(CountryCode),
          MobileNo: new FormControl(this.formGroup.get('mobileNumber')?.value),
          isGoogleSignIn: new FormControl(isGoogleSignIn)
        })
      }
      else {
        this.createNewInstituteFormGroup = new FormGroup({
          EmailID: new FormControl(emailID),
          AdminFirstName: new FormControl(username),
          AdminLastName: new FormControl(''),
          Password: new FormControl(password),
          CountryCode: new FormControl('+91'),
          MobileNo: new FormControl(),
          isGoogleSignIn: new FormControl(isGoogleSignIn)
        })
      }
      this.authService.createNewInstituteRecord(this.createNewInstituteFormGroup.value).pipe(takeUntil(this.destroy$))
        .subscribe((result) => {
          if (result.objStatusCode == 1) {
            this.spinner.hide();
            if (result.Data.InstituteID != 0) {
              const username = result.Data.EmailID;
              this.router.navigate(['/login', username, '']);
              this.dataStoreService.setData('isLoginThroughInstituteRegistration', "true");
              sessionStorage.setItem('instituteRegistrationData', JSON.stringify(result.Data));
            }
          }
          else {
            this.spinner.hide();
            if (result.Message === "This Email ID is already registered , please enter another email id for registration") {
              //  this.showError = true;
              this.buttonClicked = false;
              if (isGoogleSignIn) {
                Swal.fire({
                  icon: 'error',
                  text: result.Message
                });
              }
              else {
                this.existError = result.Message;
              }
            }
            else if (result.Message.includes('Organization is already registered with this')) {
              this.spinner.hide();
              // this.showError = true;
              this.buttonClicked = false;
              if (isGoogleSignIn) {
                Swal.fire({
                  icon: 'error',
                  text: result.Message
                });
              }
              else {
                this.existError = result.Message;
              }
            }
          }
        });
    }
    catch (ex) {
      this.logService.InsertExceptionLog(ex, 'Institute Registration', 'createNewInstituteRecord');
      console.log("Error in createNewInstituteRecord : " + ex);
    }
  }

  selectedCountryCode(selectedCountry) {

    this.selectedCountry = selectedCountry.target.value;
    if (this.selectedCountry === 'in') {
      this.mobileMaxlength = 10;
      this.formGroup.get('mobileNumber').setValidators([Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]);
    } else {
      this.mobileMaxlength = 13;
      this.formGroup.get('mobileNumber').setValidators([Validators.required, Validators.minLength(13), Validators.maxLength(13), Validators.pattern('^[0-9]*$')]);
    }
  }

  toggleForm() {
    this.signUp = !this.signUp;
    // this.signUp = false;
    this.showForm = true;
    this.showError = false;
  }

  back() {
    this.signUp = !this.signUp;
    this.showForm = false;
  }

  handleSuccess(data) {
    this.reCaptchaVerfication = data;
  }

  togglePasswordVisibility(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  onKeyDown(event: KeyboardEvent) {
    const key = event.key;
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];
    if (!/[0-9]/.test(key) && !allowedKeys.includes(key)) {
      event.preventDefault();
    }
  }

  async handleCredentialResponse(response: any) {
    try {
      if (response.credential) {
        const idToken = response.credential;
        const decodedToken = this.decodeIdToken(idToken);

        if (decodedToken) {
          const userId = decodedToken.sub;
          const userName = decodedToken.name;
          const userEmail = decodedToken.email;
          const password = this.generateRandomPassword()

          this.createNewInstituteRecord(true, userName, password, userEmail);

        }
      }
    } catch (ex) {
      console.log("Error in handleCredentialResponse : " + ex);
    }
  }

  decodeIdToken(idToken: string) {
    try {
      const payload = JSON.parse(atob(idToken.split('.')[1]));
      return payload;
    } catch (error) {
      console.error("Error in decodeIdToken : ", error);
      return null;
    }
  }


  generateRandomPassword(): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let password = '';
    for (let i = 0; i < 5; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      password += characters[randomIndex];
    }
    return password;
  }

  ngOnDestroy() {

    this.destroy$.next();
    this.destroy$.complete();

  }
}
