import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { EyAuthService } from 'src/app/services/ey/ey-auth.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardGuard implements CanActivate {
 constructor(private auth:EyAuthService,private router:Router){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
    ): boolean | UrlTree {
      if(!this.auth.IsLoggedIn()){
        this.router.navigate(['ey-dashboard']);
        return false;
      }
      
      return this.auth.IsLoggedIn();
    }
  
}
