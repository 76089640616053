import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './common/guards/auth.guard';
import { DashboardGuard } from './common/guards/dashboard.guard';

import { LoginComponent } from './components/login/login.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { InstituteRegistrationComponent } from './components/institute-registration/institute-registration.component';
import {EmailActivationComponent} from './components/email-activation/email-activation.component';
import { EyLoginComponent } from './components/ey-login/ey-login.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent }, // Normal route
  { path: 'login/:token', component: LoginComponent },
  { path: 'login/:username/:password', component: LoginComponent },
  { path: 'not-found', component: NotFoundComponent},
  { path: 'forgot-password',component:ForgotPasswordComponent},
  { path: 'reset-password',component:ResetPasswordComponent},
  { path: 'institute-registration',component:InstituteRegistrationComponent},
  { path: 'email-activation/:instituteID',component:EmailActivationComponent},
  { path: 'ey-dashboard',component:EyLoginComponent},
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/admin/admin.module').then((m) => m.AdminModule)
  }, // lazy loading
  {
    path: 'dashboard',
    canActivate: [DashboardGuard],
    loadChildren: () =>
      import('./components/ey-dashboard/ey-dashboard.module').then((m) => m.EyDashboardModule)
  },
  { path: '', redirectTo: '/login', pathMatch: 'full' }, // redirection route
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
