export const environment = {
    production: false,
    APIUrl:'https://stagingekwebapi.eklavvya.com/',
    ProgrammingUrl:'https://programmingapi.eklavvya.com/',
    recaptcha: {
        siteKey: '6LfDyuQUAAAAADlt64SJXD07ElhAUoyHiZnIaSir',
    },
    CookieEncryptionKey: 'USYdjkch6adagsdl',
    InstituteID : "109862",
    GenAIMasterInstituteID:"98004,15200",
    IsNormalCaptcha : false
};