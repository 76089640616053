import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/services/common/auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class CommonInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private router: Router) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.url.includes(environment.ProgrammingUrl) && !request.url.includes('api.hubapi.com')) {
      if (request.headers.has('Skip-Interceptor')) {
        return next.handle(request.clone({ headers: request.headers.delete('Skip-Interceptor') }));
      }
      else {
        let tokenizedReq = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.authService.getToken()}`,
            'IsAdmin': `true`,
            'BrowserData': `${sessionStorage.getItem('ipAddress')},${this.authService.detectBrowser()},${this.authService.detectOS()}`,

            // ProxyAuthenticate : `${this.authService.detectIP()},${this.authService.detectBrowser()},${this.authService.detectOS()}`
          }
        });
        return next.handle(tokenizedReq).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error instanceof HttpErrorResponse && !navigator.onLine) {
              Swal.fire({
                icon: 'error',
                title: 'Internet connection failed',
                html: 'It seems that you have lost your internet connection. Please refresh the page or try again.',
                showCancelButton: true,
              });
              console.error('Internet connection failed');
            }
            if (error.status === 401) {
              this.router.navigate(['/login']);
            } else if (error.status === 0) {
              console.error('CORS error occurred:', error);
            }
            return throwError(error);
          })
        );
      }
    } else {
      return next.handle(request);
    }
  }
}