import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InstituteRegistrationService {

  constructor(private router: Router, private http: HttpClient) { 
    
  }
  private trackedUrls: string[] = [];
  private headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: '*/*'
  });

  createNewInstituteRecord(data): Observable<any> {
    const body = new HttpParams({ fromObject: data });
    const options = { headers: this.headers };
    return this.http.post(`${environment.APIUrl}Institute/CreateNewInstituteRecord`, body, options);
  }


  get(url: string): Observable<any> {
    this.trackUrl(url);
    return this.http.get(url);
  }

  post(url: string, data: any): Observable<any> {
    this.trackUrl(url);
    return this.http.post(url, data);
  }

  // Track the URL
  private trackUrl(url: string): void {
    this.trackedUrls.push(url);
  }

  // Get all tracked URLs
  getAllTrackedUrls(): string[] {
    return this.trackedUrls;
  }
}
