<div class="header-text">
    <span>This is to inform you that all remote proctored examination data of images, audio, 
        and video files of the exam candidate, will be available in the system for only 30 days.</span>
    <br>
    <span>After this period, the data will be automatically removed.
    </span>
</div>
<div class="hold-transition theme-primary bg-img"
    style="background-image: url(../../../assets/images/auth-bg/bg-10.jpg);height: 100% !important;" *ngIf="IsRegistration">
    <div class="content h-p100">
        <div class="row align-items-center justify-content-md-center h-p100">
            <div class="col-12">
                <div class="row justify-content-center g-0">
                    <div class="col-lg-5 col-md-5 col-12 mt-2">
                        <div class="bg-white rounded10 shadow-lg get_start">
                            <div class="content-top-agile p-3 text-center">
                                <h2 class="text-primary">Admin Login</h2>
                                <p class="mb-0">Sign in to continue to Eklavvya.</p>
                            </div>
                            <div class="p-40">
                                <form form class="login-form" [formGroup]="formGroup" (ngSubmit)="Userlogin()">
                                    <span *ngIf="isRole" class="asterisk">*Please select role</span>
                                    <div class="form-group">
                                        <div class="input-group mb-3">
                                            <span class="input-group-text bg-transparent">
                                                <i class="fa fa-list" aria-hidden="true"></i></span>
                                            <select class="form-control select2 bg-transparent" aria-hidden="true"
                                                formControlName="Role">
                                                <option value="0" disabled>-- Select --</option>
                                                <option value="1" selected="selected">Super Admin</option>
                                                <option value="2">Exam Administrator</option>
                                                <option value="7">Subject Expert</option>
                                                <!-- <option value="11" >Cactus Admin</option> -->
                                                <option value="13">Result Viewer</option>
                                                <option value="10" >Branch Admin</option>
                                                <option value="14" >Manager</option>
                                                <option value="16">Examiner</option>
                                                <option value="18">Exam Supervisor</option>
                                                <option value="19">Proctor Admin</option>
                                                <option value="20">Candidate Management</option>
                                                <!-- <option value="21">Center Admin</option> -->
                                            </select>
                                        </div>                                        
                                    </div>
                                    <span *ngIf="isUsername" class="asterisk">*Please enter username</span>
                                    <div class="form-group">
                                        <div class="input-group mb-3">
                                            <span class="input-group-text bg-transparent">
                                                <i class="fa fa-user" aria-hidden="true"></i></span>
                                            <input type="text" class="form-control ps-15 bg-transparent"
                                                formControlName="Username" placeholder="Username">
                                        </div>
                                    </div>
                                    <span *ngIf="isPassword" class="asterisk">*Please enter password</span>
                                    <div class="form-group">
                                        <div class="input-group mb-3">
                                            <span class="input-group-text  bg-transparent">
                                                <i class="fa fa-lock" aria-hidden="true"></i></span>
                                            <input #NewPassword  class="form-control ps-15 bg-transparent"
                                                formControlName="Password" placeholder="Enter Password"
                                                type="{{ isPasswordVisible ? 'text' : 'password' }}"
                                            />
                                            <div class="input-group-append">
                                                <span class="input-group-text" id="toggle-new-password" mat-icon-button matSuffix (click)="togglePasswordVisibility()">
                                                    <mat-icon>{{ isPasswordVisible ? 'visibility' : 'visibility_off' }}</mat-icon>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="checkbox">
                                                <input type="checkbox" id="basic_checkbox_1"
                                                    formControlName="rememberMe">
                                                <label for="basic_checkbox_1" style="padding: 6px;">Remember Me</label>
                                            </div>
                                        </div>

                                        <!-- /.col -->
                                        <div class="col-6">
                                            <div class="fog-pwd text-end">
                                                <a [routerLink]="['/forgot-password']" class="hover-warning"><i
                                                        class="ion ion-locked" ></i> Forgot password?</a><br>
                                            </div>
                                        </div>
                                        <div *ngIf="isNormalCaptcha" class="col-sm-12 row mt-2">
                                            <div class="text-center col-sm-6" style="display: flex; justify-content: center;">
                                                <!-- Custom Captcha Display -->
                                                <div class="captcha-box">
                                                <span class="generated-captcha"><s>{{ generatedCaptcha }}</s></span>
                                                <button class="refresh-captcha" type="button" (click)="generateCustomCaptcha()"><i class="fa-solid fa-rotate-right"></i></button>
                                                </div>
                                            </div>
                                            
                                            <!-- Input Box for Custom Captcha -->
                                            <div class="input-captcha col-sm-6" style="display: flex; justify-content: center;">
                                                <input [formControl]="customCaptchaControl" placeholder="Enter Captcha" class="form-control">
                                            </div>
                                            
                                            <span *ngIf="captchaErrorMessage" class="asterisk text-center" style="margin-top: 10px;margin-left: 60px;">{{captchaErrorMessage}}</span>
                                        </div>
                                        <div *ngIf="!isNormalCaptcha" class="col-sm-12 row mt-2">
                                            <div class="text-center" style="display: flex;
                                            justify-content: center;">
                                            <ngx-recaptcha2 #captchaElem class="recaptcha_div" [siteKey]="CaptchaSiteKey" [size]="size" (success)="handleSuccess($event)" [hl]="lang"
                                                [theme]="theme" [type]="type" formControlName="recaptcha">
                                            </ngx-recaptcha2>
                                            </div>
                                            <div class="col-sm-3"></div>
                                        </div>
                                        <span *ngIf="ErrorMessage" class="asterisk text-center">{{ErrorMessage}}</span>
                                        <!-- /.col -->
                                        <div class="row col-sm-12 mt-3">
                                            <div class="col-sm-4"></div>
                                            <div class="col-sm-5">
                                                <button type="submit" style="width: 95%;height: 34px;border-radius: 4px;" class="mb-2 btn btn-danger mt-10">SIGN IN</button>
                                            </div>
                                        </div>
                                        <div class="row col-sm-12 mt-2">
                                            <div class="col-sm-4"></div>
                                            <div class="col-sm-6"> <div class="mb-2" id="google-button"></div></div>
                                            <div class="col-sm-2"></div>
                                        </div>
                                        <!-- /.col -->
                                    </div>
                                </form>
                                <!-- <div class="text-center mt-1">
                                    <p class="mt-2 mb-0 ">Don't have an account? 
                                        <a href="auth_register.html" [routerLink]="['/reset-password']"
                                            class="text-warning ">Sign Up
                                        </a>
                                    </p>
                                </div> -->
                            </div>
                        </div>

                        <div class="row col-sm-12 mt-2">
                            <div class="col-sm-4"></div>
                            <div class="col-sm-4 text-center ">
                               
                            </div>
                            <div class="col-sm-4"></div>
                        </div>
                        <!-- <div class="text-center">
                             <p class="gap-items-2" style="margin-left:-30px">
                                <a class="btn btn-social-icon btn-round btn-google" href="#">
                                    <i class="fa fa-brands fa-google" style="background-color: #dd4b39;
                                    border-color: #dd4b39;
                                    color: #ffffff;
                                    padding: 6px;
                                    border-radius: 15px;"></i></a>
                            </p>
                            <div style="margin-left: 200px;margin-top: 13px;" id="google-button"></div>
                            <div data-use_fedcm_for_prompt = true></div> 
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Progress Loader -->
<div>
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#1f85d6" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> {{spinnerText}} </p>
  </ngx-spinner>
</div>