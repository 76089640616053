import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/services/common/auth.service';
import { EncryptionService } from 'src/app/services/common/encryption.service';
import { EyAuthService } from 'src/app/services/ey/ey-auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ey-login',
  templateUrl: './ey-login.component.html',
  styleUrls: ['./ey-login.component.scss']
})
export class EyLoginComponent {

  formGroup: FormGroup;
  ipAddress: string;
  userName = new FormControl;
  password = new FormControl;
  spinnerText : string;
  isUsername = 0;
  isPassword = 0;
  isInvalidLogin = 0;
  ErrorMessage: string;

  private destroy$ = new Subject<void>();

  constructor(private authService: AuthService,
    private router: Router,
    private encrypt: EncryptionService,
    private spinner: NgxSpinnerService,
  private eyAuthService : EyAuthService) { }

  ngOnInit()
  {
    try{
      this.spinner.hide();
      this.formGroup = new FormGroup({
        EmailAddress: new FormControl(""),
        UserPassword: new FormControl(""),
        UserType: new FormControl("22")
      });
      this.authService.getIPAddress().then(ipAddress => {
        this.ipAddress = ipAddress;
      });
    }
    catch(ex)
    {
      console.log("Exception in ngOnInit" + ex);
    }
  }

  Userlogin() {
    try {
      this.spinnerText = 'Logging in...'
      this.ErrorMessage = "";
      this.spinner.show();

      if (this.userName.value == "" || this.userName.value == null) {
        this.isUsername = 1;
        this.spinner.hide();
      }
      else if (this.password.value == "" || this.password.value == null) {
        this.isPassword = 1;
        this.spinner.hide();
      }
      else {
        this.formGroup.get('EmailAddress').setValue(this.userName.value);
        this.formGroup.get('UserPassword').setValue(this.password.value);
        this.formGroup.addControl('IpAddress', new FormControl(this.ipAddress));
        this.formGroup.addControl('Browser', new FormControl(this.authService.detectBrowser()));
        this.formGroup.addControl('OperatingSystem', new FormControl(this.authService.detectOS()));
        this.formGroup.addControl('IsAdmin', new FormControl(true));
        sessionStorage.setItem('ipAddress', this.ipAddress);
        this.eyAuthService.AuthenticateInstituteUserForEY(this.formGroup.value)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (result) => {
              if (result.objStatusCode == 1 && result.Data.Token != null && result.Data.Token != "") {
                const subscriptionEndDate = new Date(result.Data.SubscriptionEndDate);
                const difference = Math.floor((subscriptionEndDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
                if (difference <= 10 && difference > 0) {
                  Swal.fire({
                    title: 'Caution',
                    text: `Your subscription would end in ${difference} days`,
                    icon: 'warning'
                  })
                }
                else if (difference == 0) {
                  Swal.fire({
                    title: 'Caution',
                    text: `Your subscription will end today`,
                    icon: 'warning'
                  })
                }
                else if (difference < 0) {
                  Swal.fire({
                    title: 'Caution',
                    text: `Subscription End Date is elapsed. Please Renew Your Subscription Today.`,
                    icon: 'warning'
                  })
                }
                this.eyAuthService.setToken(result.Data.Token);

                sessionStorage.setItem('param1', result.Data.InstituteID);
                sessionStorage.setItem('param2', result.Data.UserType);
                sessionStorage.setItem('param4', result.Data.InstituteUserID);

                this.router.navigate(['dashboard/org-analysis']);
              }
              else {
                this.isInvalidLogin = 1;
                this.ErrorMessage = "Invalid Username or Password."
                this.spinner.hide();
              }
              this.spinner.hide();
            }, (err: Error) => {
              console.log(err.message);
              this.spinner.hide();
            });
      }
    } catch (ex) {
      console.log("Error in Userlogin : " + ex);
      this.spinner.hide();
    }
  }
}
